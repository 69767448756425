<template>
	<div class="order-create__section">
    <div class="order-create__section-label section-label"
         v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
      <div class="admin-edit" @click="editTranslate(['poshta_PersonalDelivery'])"></div>
      {{$t('poshta_PersonalDelivery.localization_value.value')}}
    </div>

    <div class="order-create__row custom-row">
      <div class="order-create__col custom-col custom-col--33 custom-col--md-50 custom-col--sm-100"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate(['poshta_contactName'])"></div>
        <DefaultInput
            :label="$t('common_name.localization_value.value')"
            :type="'text'"
            v-bind:class="{'ui-no-valid': recipientInformation.validation.personalName}"
            :errorTxt="$t(`${recipientInformation.validationTranslate.personalName}.localization_value.value`)"
            :error="recipientInformation.validation.personalName"
            v-model="recipientInformation.information.personalName"
        />
      </div>

      <div class="order-create__col custom-col custom-col--33  custom-col--md-50 custom-col--sm-100"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate(['common_phone'])"></div>
        <DefaultInput
            :label="$t('common_phone.localization_value.value')"
            :type="'text'"
            v-bind:class="{'ui-no-valid': recipientInformation.validation.personalPhone}"
            :errorTxt="$t(`${recipientInformation.validationTranslate.personalPhone}.localization_value.value`)"
            :error="recipientInformation.validation.personalPhone"
            v-model="recipientInformation.information.personalPhone"
        />
      </div>
      <div class="order-create__col custom-col custom-col--33  custom-col--md-50 custom-col--sm-100"
           v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <div class="admin-edit" @click="editTranslate(['common_email'])"></div>
        <DefaultInput
            :label="$t('common_email.localization_value.value')"
            :type="'text'"
            v-bind:class="{'ui-no-valid': recipientInformation.validation.personalEmail}"
            :errorTxt="$t(`${recipientInformation.validationTranslate.personalEmail}.localization_value.value`)"
            :error="recipientInformation.validation.personalEmail"
            v-model="recipientInformation.information.personalEmail"
        />
      </div>
    </div>

		<div class="order-create__section-label section-label"
         v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
      <div class="admin-edit" @click="editTranslate([
          'poshta_deliveryAddress',
          'poshta_deliveryAddressTooltip'
          ])"></div>
      {{ $t('poshta_deliveryAddress.localization_value.value') }}
			<v-popover
							class="site-tooltip"
							offset="5"
							placement="top"
							trigger="hover"

			>
				<TooltipBtn
								class="tooltip-target"
				/>
				<template slot="popover">
					<p>
						<b>{{ $t('poshta_deliveryAddress.localization_value.value') }}</b>
					</p>
					<p>
            {{$t('poshta_deliveryAddressTooltip.localization_value.value')}}
					</p>
				</template>
			</v-popover>
		</div>

		<div class="order-create__section-sub">
			<div class="order-create__row custom-row">
				<div class="order-create__col custom-col custom-col--33  custom-col--md-50 custom-col--sm-100"
             v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
          <div class="admin-edit" @click="editTranslate(['common_address'])"></div>
          <DefaultInput
              :label="$t('common_address.localization_value.value')"
              :type="'text'"
              v-bind:class="{'ui-no-valid': recipientDeliveryAddress.validation.address}"
              :errorTxt="$t(`${recipientDeliveryAddress.validationTranslate.address}.localization_value.value`)"
              :error="recipientDeliveryAddress.validation.address"
              v-model="recipientDeliveryAddress.delivery.address"
          />
				</div>
				<div class="order-create__col custom-col custom-col--33  custom-col--md-50 custom-col--sm-100"
             v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
          <div class="admin-edit" @click="editTranslate(['common_city'])"></div>
          <DefaultInput
              :label="$t('common_city.localization_value.value')"
              :options="options"
              v-bind:class="{'ui-no-valid': recipientDeliveryAddress.validation.city}"
              :errorTxt="$t(`${recipientDeliveryAddress.validationTranslate.city}.localization_value.value`)"
              :error="recipientDeliveryAddress.validation.city"
              v-model="recipientDeliveryAddress.delivery.city"
          />
				</div>
				<div class="order-create__col custom-col custom-col--33  custom-col--md-50 custom-col--sm-100"
             v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
          <div class="admin-edit" @click="editTranslate(['common_region'])"></div>
          <DefaultInput
              :label="$t('common_region.localization_value.value')"
              :options="options"
              v-bind:class="{'ui-no-valid': recipientDeliveryAddress.validation.region}"
              :errorTxt="$t(`${recipientDeliveryAddress.validationTranslate.region}.localization_value.value`)"
              :error="recipientDeliveryAddress.validation.region"
              v-model="recipientDeliveryAddress.delivery.region"
          />
				</div>
				<div class="order-create__col custom-col custom-col--33  custom-col--md-50 custom-col--sm-100"
             v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
          <div class="admin-edit" @click="editTranslate(['common_country' ])"></div>
          <DefaultSelect
              :label="$t('common_country.localization_value.value')"
              :options="options"
              :optionsLabel="'label'"
              v-bind:class="{'ui-no-valid': recipientDeliveryAddress.validation.country}"
              :errorTxt="serverError ? recipientDeliveryAddress.validationTxt.country : $t(`${recipientDeliveryAddress.validationTranslate.country}.localization_value.value`)"
              :error="recipientDeliveryAddress.validation.country"
              :selected="recipientDeliveryAddress.delivery.country"
              @change="changeCountry"
          />
          <div class="site-link site-link-alt mt-3 ml-auto wfc	d-flex"
               v-if="recipientDeliveryAddress.delivery.country !== ''"
               v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
            <div class="admin-edit" @click="editTranslate([
                        'express_ViewCustomLimits',
                      ])"></div>
            <div
                    @click="showCustomLimitsPopup(recipientDeliveryAddress.getCountry().id, 'isModalCustomLimitsPopup')"
            >
              {{$t('express_ViewCustomLimits.localization_value.value')}}
            </div>
          </div>
				</div>
				<div class="order-create__col custom-col custom-col--16  custom-col--md-25 custom-col--sm-50"
             v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
          <div class="admin-edit" @click="editTranslate(['common_zipCode' ])"></div>
          <DefaultInput
              :label="$t('common_zipCode.localization_value.value')"
              :type="'text'"
              v-bind:class="{'ui-no-valid': recipientDeliveryAddress.validation.zip}"
              :errorTxt="$t(`${recipientDeliveryAddress.validationTranslate.zip}.localization_value.value`)"
              :error="recipientDeliveryAddress.validation.zip"
              v-model="recipientDeliveryAddress.delivery.zip"
          />
				</div>
			</div>
		</div>

    <CustomLimitsPopup
            @close="isModalCustomLimitsPopup = false"
            v-if="isModalCustomLimitsPopup"
    />

	</div>
</template>

<script>
	import {mixinDetictingMobile} from "../../../../../../mixins/mobileFunctions";
	import {radioButtonsFunc} from "../../../../../../mixins/creationPageMixins/radioButtonsFunc";
	import DefaultSelect from "../../../../../UI/selectiones/DefaultSelect/DefaultSelect";
	import DefaultInput from "../../../../../UI/inputs/DefaultInput/DefaultInput";
	import TooltipBtn from "../../../../../UI/tooltips/TooltipBtn/TooltipBtn";
  import {customLimitsMixin} from "../../../../../../mixins/commonMixins/customLimitsMixin";
  import CustomLimitsPopup from "../../../../../coreComponents/Popups/CustomLimitsPopup/CustomLimitsPopup";
  import {countriesMixin} from "../../../../../../mixins/commonMixins/countriesMixin";

	export default {
		name: "StepTwo",

		components:{
      CustomLimitsPopup,
			DefaultSelect,
			DefaultInput,
			TooltipBtn,
		},

		mixins: [mixinDetictingMobile, radioButtonsFunc, customLimitsMixin, countriesMixin],

    props: {
      recipientInformation: Object,
      recipientDeliveryAddress: Object,
      serverError: {
        type: Boolean,
        default: false,
      }
    },

		data() {
			return {
				options: [{}],

        isModalCustomLimitsPopup: false,
			}
		},

    mounted() {
      this.$store.dispatch('fetchCountries').then(() => {
        let countries = this._.toArray(this.$store.getters.getCountries)
        this.options = this.getCountriesForRecipient(countries)
      })
    },

		methods: {
      changeCountry(val) {
        if(val === '') {
          this.recipientDeliveryAddress.setCountry('')
          return
        }
        this.recipientDeliveryAddress.setCountry(val)
      },
		}

	}
</script>

<style scoped lang="scss">
	@import "../../../../../../scss/mixins/mixins";

	.toggle-block.small-size .toggle-block__details{
		width: 100%;
		max-width: 358px;
		min-height: 310px;

		@include for-768{
			margin-top: 20px;
			min-height: initial;
		}
	}

	.toggle-block__details-body{
		height: initial;
	}
</style>
